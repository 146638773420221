@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		box-sizing: border-box;
	}

	html,
	body,
	#root {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}
}

:root {
	--toastify-color-dark: #14142b;
	--toastify-color-info: #0576f0;
	--toastify-color-success: #18bb0c;
	--toastify-color-warning: #eaac30;
	--toastify-color-error: #ef4444;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #005bd4 #001021;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
	height: 4px;
	width: 4px;
}
*::-webkit-scrollbar-track {
	border-radius: 0px;
	background-color: #001021;
}

*::-webkit-scrollbar-track:hover {
	background-color: #001021;
}

*::-webkit-scrollbar-track:active {
	background-color: #001021;
}

*::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: #005bd4;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: #005bd4;
}

*::-webkit-scrollbar-thumb:active {
	background-color: #005bd4;
}
